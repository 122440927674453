<template>
  <div>
    <WidgetSectionHero />
    <WidgetTradeInPreview />
    <WidgetSectionSalesHits />
    <WidgetSectionSaleBanner :class="[sectionStyles, 'container']" />
    <WidgetSectionPopularModels class="my-7" is-show-popular-categories />
    <WidgetSectionTradeIn class="mb-7" />
    <WidgetSectionMapContacts class="mb-7" />
    <WidgetSectionReviews />
    <WidgetSectionFaq />
  </div>
</template>

<script setup lang="ts">
import { sectionStyles } from '@/utils'
import { DEVICES_SORT_BY } from '~/constants'

const devicesStore = useDevicesStore()

// загружаем устройства
if (!devicesStore.list?.length) {
  await useAsyncData(
    'fetchPopularModels',
    () => devicesStore.getList({
      SortBy: DEVICES_SORT_BY.IS_POPULAR,
      SortDirection: 'desc',
      IsNew: 0,
      ParentId: '21876e69-9d87-11e5-9312-f452145fc090',
    }).then(() => true),
  )
}
</script>

<style>
.varioqub-antiflicker {opacity: 0 !important;}
</style>